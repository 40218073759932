var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "trade-records" }, [
    _c(
      "div",
      { staticClass: "table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData,
              "element-loading-text": _vm.loadingTxt,
              "header-cell-style": {
                textAlign: "center",
                background: "#FAFAFA"
              },
              "cell-style": { textAlign: "center" }
            }
          },
          [
            _c("el-table-column", {
              attrs: { label: "序号", type: "index", index: index => index + 1 }
            }),
            _vm._l(_vm.columnData, function(item) {
              return _c("el-table-column", {
                key: item.label,
                attrs: { prop: item.prop, label: item.label },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function({ row }) {
                        return [
                          item.prop === "status"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("filterTrade")(row[item.prop])
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [_vm._v(_vm._s(row[item.prop]))])
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }