
import { Component, Vue } from 'vue-property-decorator'
// import Pagination from '@/components/Pagination/index.vue'
import { settlementTradeRecordBatchDetail } from '@/api/financing'
import { loadingText } from '@/utils/defaultGoalConfig'
const columnData = [
  { label: '交易编号', prop: 'tradeNo' },
  { label: '姓名', prop: 'name' },
  { label: '卡号', prop: 'bankAccount' },
  { label: '开户行', prop: 'openBank' },
  { label: '金额', prop: 'amount' },
  { label: '交易状态', prop: 'status' }
]

const tradeStatusList = [
  {
    label: '未支付',
    value: '0'
  },
  {
    label: '已提交',
    value: '1'
  },
  {
    label: '成功',
    value: '2'
  },
  {
    label: '部分成功',
    value: '4'
  },
  {
    label: '支付失败',
    value: '3'
  }
]

@Component({
  components: {
    // Pagination
  },
  filters: {
    filterTrade(val: number) {
      if (val === undefined || val === null) return
      const value = val.toString()
      return tradeStatusList.find((v) => v.value == value)?.label
    }
  }
})
export default class TradeRecords extends Vue {
  private loadingTxt: string = loadingText
  private tableData: object[] = []
  // public paginationParam: any = {
  //   pageIndex: 1,
  //   pageSize: 10,
  //   total: 0
  // }
  private columnData = columnData

  private loading = false
  private usageList = []

  private tradeStatusList = tradeStatusList // 交易状态

  created() {
    this.query()
  }

  // 查询
  async query() {
    this.loading = true
    settlementTradeRecordBatchDetail({ transactionId: this.$route.params.id })
      .then((res) => {
        if (res.data.data) {
          this.tableData = res.data.data
        }
      })
      .finally(() => {
        this.loading = false
      })
  }
}
